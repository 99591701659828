import React from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import styles from './RoleList.module.css'
import Divider from '@mui/material/Divider'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Fragment, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import { CreateRole } from './CreateRole'
// import PersonIcon from '@mui/icons-material/Person';

const disabledRoles = [
  'ROLE_READ_ONLY',
  'ROLE_ADMIN',
  'ROLE_SERVICE',
  'ROLE_READ_WRITE'
]

const CheckboxComponent = (props: any) => {
  const rowValues = props.params
  return (
    <Checkbox
      disabled={disabledRoles.includes(rowValues.row.authority)}
      defaultChecked={rowValues.row.isActive}
    />
  )
}

const _col=[
  { field: 'authorityAlias', headerName: 'Role Name', width: 300,search:true },
  { field: 'policy', headerName: 'Policy', width: 300,search:true },
  { field: 'dateCreated', headerName: 'Date Created', width: 300,search:true },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 300,
    search:true
  },
  {
    field: 'isActive',
    headerName: 'Enabled',
    width: 100,    
    renderCell: (params: any) => <CheckboxComponent params={params} />
  }
]
const columns: GridColDef[] = _col.map(x=>x);



export const RoleList = (roles: any) => {
  const [roleListToShow, setRoleListToShow] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [roleShow, setRoleShow] = useState(true)
  const [selectedRowData, setSelectedRowData] = useState({})

  useEffect(() => {
    const roleTab = roles.roleTabClicked
    setRoleShow(roleTab)
  }, [roles])

  useEffect(() => {
    const roleList = roles['roles'].roles || []

    const filtered = roleList
      ? roleList.filter((item: any) => {
        const cols=_col.filter(x=>x.search) 
        let found=false;
        cols.forEach((col:any)=>{         
         if(!found){
          try {  found=JSON.stringify(item[col.field]).toLowerCase().includes(searchVal.toLowerCase())
          }
          catch(e){
           
          }
         }
        })
        return found;  
        })
      : []

    setRoleListToShow(filtered)
  }, [searchVal, roles,roleShow])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  const cancelRole = () => {
    setRoleShow(true)
    roles.viewModeChanged('list');
  }

  const handleCreateRoleClick = () => {
    setRoleShow(false)
    roles.viewModeChanged('create');
    setSelectedRowData({})
    roles.setRoleTabClicked(false)
    setSearchVal('')
  }

  const handleOnCellClick = (params: any) => {
    if (params && params.field === 'authorityAlias') {
      setRoleShow(false)
      roles.viewModeChanged('edit');
      setSelectedRowData(params)
      roles.setRoleTabClicked(false)
      setSearchVal('')
    }

    if (params && params.field === 'isActive') {
      if (!disabledRoles.includes(params.row.authority)) {
        const updateRole = roles.roles.updateRole
        const temp = { ...params.row }
        temp.isActive = !params.row.isActive

        updateRole(temp, params.id, {}, false, true)
      }
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <Fragment>
      {roleShow && (
        <div className={styles.main}>
          Roles
          <span className={styles.filters}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <Grid item xs={4} className='searchBox'>
                <Paper
                  component='form'
                  sx={{
                    p: '2px 4px',
                    bgcolor: '#f2f6fc',
                    display: 'flex',
                    alignItems: 'center',
                    width: 400,
                    height: 60
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder='Search Roles'
                    inputProps={{ 'aria-label': 'search global' }}
                    onChange={onChange}
                    onKeyDown={handleKeyPress}
                    className='inputClass'
                  />
                  <SearchIcon />
                </Paper>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                {/* <span> <PersonIcon sx={{ float: 'right'}} /> </span> */}
                <span>
                  <Button
                    className='createBttn'
                    onClick={handleCreateRoleClick}
                  >
                    Create New Role
                  </Button>
                </span>
              </Grid>
            </Grid>
            <div />
          </span>
          <Divider sx={{ paddingTop: 2 }} />
          <div style={{ height: 600, paddingTop: 10 }}>
            <DataGrid
              rows={roleListToShow}
              columns={columns}
              pageSize={8}
              rowsPerPageOptions={[10]}
              onCellClick={handleOnCellClick}
            />
          </div>
        </div>
      )}

      {!roleShow && (
        <div>
          <CreateRole
            selectedRowData={selectedRowData}
            cancelRole={cancelRole}
            roles={roles}
          />
        </div>
      )}
    </Fragment>
  )
}