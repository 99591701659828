import { Box, Typography } from "@mui/material"
import React from "react"

export const  TabPanel=(props: any)=> {
    const { children, value, index, ...other } = props

    const dynamicSX = {
      p:3
    }
  
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={dynamicSX}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

export const a11yProps=(index: number)=> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}  