import * as React from 'react'
// import styles from './UserList.module.css'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText
} from '@mui/material'
import Warning from '@mui/icons-material/Warning'
// import { useState } from 'react'

export const ConfirmPopup = (props: any) => {
  const { showPopup, setShowPopup, deletePolicy } = props

  return (
    <div>
      <Dialog open>
        <DialogTitle id='confirm-dialog'>
          <Warning color='error' /> Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This Policy will be deleted permanently
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {
              setShowPopup(false)
            }}
            style={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              deletePolicy()
              setShowPopup(false)
            }}
            style={{ textTransform: "capitalize" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
