/* eslint-disable prettier/prettier */
import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import styles from './PolicyList.module.css'
import { Button, FormControl, Grid, Checkbox, Link } from '@mui/material'
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'

import '../styles.module.css';

interface IResourcePermissionProps {
    permission: string;
    permissionName: string;
    resourceType: string;
    resourceTypeDes: string
}
interface IResourceTypeProps {
    resource_type: string;
    resource_type_desc: string;
    permissions: Array<IResourcePermissionProps>
}

interface IPermissionProps {
    resource_type: string;
    effect: string;
    action: Array<string>
}

// const resourceTypes = [
//     {
//         "resource_type": "urm",
//         "resource_type_desc": "User/Role Management",
//         "permissions": [
//             {
//                 "resourceType": "urm",
//                 "resourceTypeDesc": "User/Role Management",
//                 "permission": "urm_management",
//                 "permissionName": "URM Management"
//             }
//         ]
//     },
//     {
//         "resource_type": "model",
//         "resource_type_desc": "Model",
//         "permissions": [
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "view",
//                 "permissionName": "View"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "create",
//                 "permissionName": "Create"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "edit",
//                 "permissionName": "Edit"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "delete",
//                 "permissionName": "Delete"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "run",
//                 "permissionName": "Save as overwrite"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "run_new_version",
//                 "permissionName": "Save as New Version"
//             },
//             {
//                 "resourceType": "model",
//                 "resourceTypeDesc": "Model",
//                 "permission": "copy",
//                 "permissionName": "Copy"
//             }
//         ]
//     },
//     {
//         "resource_type": "scenario",
//         "resource_type_desc": "Model Scenario",
//         "permissions": [
//             {
//                 "resourceType": "scenario",
//                 "resourceTypeDesc": "Model Scenario",
//                 "permission": "create",
//                 "permissionName": "Create"
//             },
//             {
//                 "resourceType": "scenario",
//                 "resourceTypeDesc": "Model Scenario",
//                 "permission": "view",
//                 "permissionName": "View"
//             },
//             {
//                 "resourceType": "scenario",
//                 "resourceTypeDesc": "Model Scenario",
//                 "permission": "delete",
//                 "permissionName": "Delete"
//             },
//             {
//                 "resourceType": "scenario",
//                 "resourceTypeDesc": "Model Scenario",
//                 "permission": "edit",
//                 "permissionName": "Edit"
//             },
//             {
//                 "resourceType": "scenario",
//                 "resourceTypeDesc": "Model Scenario",
//                 "permission": "designate",
//                 "permissionName": "Designate"
//             }
//         ]
//     },
//     {
//         "resource_type": "growth_metrics",
//         "resource_type_desc": "Scenario Growth Metrics",
//         "permissions": [
//             {
//                 "resourceType": "growth_metrics",
//                 "resourceTypeDesc": "Scenario Growth Metrics",
//                 "permission": "view",
//                 "permissionName": "View"
//             },
//             {
//                 "resourceType": "growth_metrics",
//                 "resourceTypeDesc": "Scenario Growth Metrics",
//                 "permission": "edit",
//                 "permissionName": "Edit"
//             }
//         ]
//     }
// ]

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const CreatePolicy = (policies: any) => {

    const [policyName, setPolicyName] = useState('')
    const [policyDescription, setPolicyDescription] = useState('');
    const [nameCheck, setNameCheck] = useState(false);
    const [disableSave, setDisableSave] = useState(false)

    const [permissions, setPermissions] = useState([{resource_type: 'model', effect: 'allow', action: ['view']}]);
    const policyJson = policies.selectedRowData.row ? policies.selectedRowData.row.policyJson : [];
    const prevPolicyName = usePrevious(policyName)

    const policyObj = policies.policies.policies;
    const [resourceTypes, setResourceTypes] = useState(policyObj.resourceTypes || []);

    useMemo(() => {
        if (policies && policies.selectedRowData.row) {
            if ( policyJson && policyJson.permissions.length !== 0 ) {
                setPermissions([...policyJson.permissions])
            }

            if (policies.selectedRowData.row.name) {
                setPolicyName(policies.selectedRowData.row.name)
            }

            if (policies.selectedRowData.row.description) {
                setPolicyDescription(policies.selectedRowData.row.description)
            }
        }
    }, [policies])

    useEffect(() => {

        if (policyName &&
            (policyName !== prevPolicyName)) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }

    }, [policyName])

    useEffect(() => {

        const actions = permissions.map((permission: IPermissionProps) => permission.action).flat() || [];

        if (!policyName || (actions && actions.length === 0)) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
        }
    }, [policyName, permissions])


    const handlePolicyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (policies && policies.selectedRowData.row && policies.selectedRowData.row.name) {
            policies.selectedRowData.row.name = '';
        }
        setPolicyName(e.target.value)
        setNameCheck(false)
    }

    const handlePolicyDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (policies && policies.selectedRowData.row && policies.selectedRowData.row.description) {
            policies.selectedRowData.row.description = '';
        }
        setPolicyDescription(e.target.value)
    }

    const updateViewCheckBox = (resourceType: string) => {

        const checkboxId = resourceType.replace(/ /g, '') + 'view';
        const checkbox = document.getElementById(checkboxId) as  HTMLInputElement || null;
        if(checkbox){
            checkbox.checked = true;
        }
    }

    const handleCheckboxClick = (
        e: React.ChangeEvent<HTMLInputElement>,
        a: string,
        b: string,
        permission: any
    ) => {

        if(b === "edit" && e.target.checked){
            updateViewCheckBox(a);
        }
        const arr: Array<IPermissionProps> = [...permissions] || [];
        const resourceTypesArr = arr.length !==0 ? arr.map((el: IPermissionProps) => el.resource_type) : [];
        if (resourceTypesArr.includes(a)) {
            arr.forEach((element: IPermissionProps) => {
                if(element.resource_type === a) {
                    const _permissionArr = [...element.action]
                    if (e.target.checked  && !_permissionArr.includes(b)) {
                        if(b ==="edit" && !_permissionArr.includes("view")){
                            _permissionArr.push("view");
                        }
                        _permissionArr.push(b)
                        element.action = _permissionArr;

                    } else {
                        _permissionArr.forEach((item: string, index: number) => {
                            if (b && b === item && _permissionArr.includes(b)) {
                                _permissionArr.splice(index, 1);
                            }
                        });
                        element.action = _permissionArr
                    }
                }
            })
        } else {
            const permissionArray: Array<string> = []
            const permssionObj = {
                resource_type: a,
                effect: 'allow',
                action: permissionArray
            }
            permssionObj.action.push(b)

            arr.push(permssionObj)
        }
        setPermissions(arr)
    }


    const handleSave = () => {

        const policyObj = policies.policies.policies || {};

        const payloadObj = {
            name: policyName,
            description: policyDescription,
            policyJson: {
                permissions: permissions
            },
            isActive: true,
            isDeleted: false,
            currentUser: policyObj.username
        }

        const createPolicy = policyObj.createPolicy;
        const updatePolicy = policyObj.updatePolicy;

        if (!policyName) {
            setNameCheck(true)
        }

        if (!nameCheck) {
            if (policies && policies.selectedRowData.row) {
                const policyId = policies.selectedRowData.row.id;
                payloadObj['id'] = policyId;
                updatePolicy(policyId, payloadObj);
            } else {
                createPolicy(payloadObj);
            }
        }

        localStorage.setItem('UrmServiceProvider', '1')
        setTimeout(function () {
            handleCancel()
            policies.policies.setPolicyTabClicked(true)
        }, 3000)

        // handleCancel()
    }

    const handleCancel = () => {
        const policyObj = policies.policies.policies || {};
        policyObj.getPolicyData()
        policies.policies.setPolicyTabClicked(true)
        policies.cancelCreatePolicy();

    }

    const checkIfPermissionPresent = (permission: IResourcePermissionProps) => {
        let isPresent = false;

        permissions.forEach((_permission: IPermissionProps) => {
            if(_permission.resource_type === permission.resourceType){
                if(_permission.action.includes(permission.permission)){
                    isPresent = true;
                }
            }
        })
        return isPresent;
    }

    return (
        <Fragment>
            <div>
                <div style={{ paddingBottom: 20 }}>
                    <Link component="button" style={{ fontSize: 'inherit' }} onClick={handleCancel}> Policy  </Link>
                    <span>/ Create or Update</span>
                </div>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={3} className={styles.userLabel} sx={{ fontWeight: 'bold' }}>
                        {' '}
                        Name: *
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl className={styles.userText}>
                            <OutlinedInput
                                id='policName'
                                sx={{ width: 500 }}
                                value={policies && policies.selectedRowData.row && policies.selectedRowData.row.name ? policies.selectedRowData.row.name : policyName}
                                onChange={handlePolicyNameChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} className={styles.userLabel} />
                    <Grid item xs={9}>
                        {nameCheck && (
                            <div style={{ color: 'red' }}>* Name field is empty</div>
                        )}
                    </Grid>
                    <Grid item xs={3} className={styles.userLabel} sx={{ fontWeight: 'bold' }}>
                        Description :
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl className={styles.userText}>
                            <OutlinedInput
                                id='policyDescription'
                                sx={{ width: 500 }}
                                value={policies && policies.selectedRowData.row && policies.selectedRowData.row.description ? policies.selectedRowData.row.description : policyDescription}
                                onChange={handlePolicyDescChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} className={styles.userLabel} sx={{ fontWeight: 'bold' }}>
                        Permissions :
                    </Grid>
                    <Grid item xs={9} />
                    <Grid item xs={12}>
                        {permissions && [...resourceTypes].map((resource: IResourceTypeProps) => {
                            return <Grid item xs={12} key={resource.resource_type} className={styles.userLabel} >
                                <Grid container direction='row' spacing={3}>
                                    <Grid item xs={2} sx={{ fontWeight: 'bold' }}>
                                        {resource.resource_type_desc}
                                    </Grid>
                                    <Grid item xs={10} >
                                        <Grid item xs={12} >
                                            <Grid container direction='row'>
                                                {resource.permissions.map((permission: IResourcePermissionProps) => {
                                                    return <Grid item xs={3} key={permission.permission}>
                                                        <Checkbox
                                                            id={(permission.resourceType.replace(/ /g, '') + permission.permission)  || ''}
                                                            defaultChecked={checkIfPermissionPresent(permission)}
                                                            checked={checkIfPermissionPresent(permission)}
                                                            onChange={(event) =>
                                                                handleCheckboxClick(event, permission.resourceType, permission.permission, permission)
                                                            }
                                                        />
                                                        {permission.permissionName}
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <div style={{ paddingTop: 20 }}>
                    {disableSave && <Button
                        disabled={disableSave}
                        style={{ width: 30, opacity: 0.5, backgroundColor: '#1E64D7', color: 'white', textTransform: "capitalize" }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>}
                    {!disableSave && <Button
                        style={{ width: 30, backgroundColor: '#1E64D7', color: 'white', textTransform: "capitalize" }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>}
                    <Button
                        style={{ marginLeft: 10, width: 30, backgroundColor: '#1E64D7', color: 'white', textTransform: "capitalize" }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}
