import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import styles from './RoleList.module.css'
import {
  Button,
  FormControl,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  Link
} from '@mui/material'
import { Fragment, useState, useEffect, useRef } from 'react'
import { AttachPolicy } from './AttachPolicy'
import { PermissionsTable } from './RolesTab/PermissionsTable'
// import DeleteIcon from '@mui/icons-material/Delete';

import '../styles.module.css';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props
  const p3SX = { p: 3 };

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={p3SX}>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const policyList = [
  {
    id: 1,
    name: 'Anand',
    description: 'Naidu',
    dateCreated: 'Anand',
    createdBy: 35,
    policyJson: {
      permissions: [
        {
          action: ['view', 'create'],
          effect: 'allow',
          resource_type: 'model'
        }
      ]
    }
  },
  {
    id: 2,
    name: 'Nitin',
    description: 'Kumar',
    dateCreated: 'Nitin',
    createdBy: 30,
    policyJson: {
      permissions: [
        {
          action: ['view', 'edit', 'delete'],
          effect: 'allow',
          resource_type: 'model'
        }
      ]
    }
  },
  {
    id: 3,
    name: 'Swanand',
    description: 'Fulari',
    dateCreated: 'Swanand',
    createdBy: 30,
    policyJson: {
      permissions: [
        {
          action: ['view', 'delete'],
          effect: 'allow',
          resource_type: 'model'
        },
        {
          action: ['urm_management'],
          effect: 'allow',
          resource_type: 'urm'
        }
      ]
    }
  },
  {
    id: 4,
    name: 'Dib',
    description: 'Kumar',
    dateCreated: 'Dibyanshu',
    createdBy: 16,
    policyJson: {
      permissions: [
        {
          action: ['view', 'create'],
          effect: 'allow',
          resource_type: 'model'
        }
      ]
    }
  }
]

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const CreateRole = (roles: any) => {
  const [authorityKey, setAuthorityKey] = useState('')
  const [authorityAliasName, setAuthorityAliasName] = useState('')
  const [value, setValue] = useState(0)
  const [attachedPoliciesToRole, setAttachedPoliciesToRole] = useState([])
  const [policyData, setPolicyData] = useState([])
  const [existingAttached, setExistingAttach] = useState<Array<any>>([])
  const [nameCheck, setNameCheck] = useState(false)
  const [keyCheck, setKeyCheck] = useState(false)
  const [attachPolicyCheck, setAttachPolicyCheck] = useState(false)
  const [disableSave, setDisableSave] = useState(false)

  const inputSX = { width: 500 };
  const fwbSX = { fontWeight: 'bold' };

  // const prevAuthKey = usePrevious(authorityKey)
  // const prevAuthAlias = usePrevious(authorityAliasName)
  // const prevAttachedPolicies: any = usePrevious(attachedPoliciesToRole)

  useEffect(() => {
    if (roles && roles.roles) {
      const rolesData = roles.roles.roles
      setPolicyData(rolesData.policies)
      if (
        rolesData.policies &&
        roles.selectedRowData &&
        roles.selectedRowData.row
      ) {
        const attachedAlready = rolesData.policies.filter((row: any) =>
          roles.selectedRowData.row.policy.includes(row.name)
        )
        setExistingAttach(attachedAlready)
      }
      // else {
      //   const attachedAlready: any = policyList.filter((row: any) =>
      //     roles.selectedRowData.row.policy.includes(row.name)
      //   )
      //   setExistingAttach(attachedAlready)
      // }

      if (
        roles.selectedRowData &&
        roles.selectedRowData.row &&
        roles.selectedRowData.row.authority
      ) {
        setAuthorityKey(roles.selectedRowData.row.authority)
      }

      if (
        roles.selectedRowData &&
        roles.selectedRowData.row &&
        roles.selectedRowData.row.authorityAlias
      ) {
        setAuthorityAliasName(roles.selectedRowData.row.authorityAlias)
      }
    }
  }, [roles])

  useEffect(() => {
    if (authorityKey && authorityAliasName && existingAttached.length > 0) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
  }, [authorityKey, authorityAliasName, existingAttached])

  useEffect(() => {
    if (attachedPoliciesToRole.length === 0) {
      setAttachPolicyCheck(true)
      // setDisableSave(true)
    } else {
      setAttachPolicyCheck(false)
    }
  }, [attachedPoliciesToRole])

  useEffect(() => {
    if (!authorityAliasName) {
      setDisableSave(true)
    }
  }, [authorityAliasName])

  useEffect(() => {
    if (!authorityKey) {
      setDisableSave(true)
    }
  }, [authorityKey])

  const handleAuthorityKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      roles &&
      roles.selectedRowData.row &&
      roles.selectedRowData.row.authority
    ) {
      roles.selectedRowData.row.authority = ''
    }
    setAuthorityKey(e.target.value)
    setKeyCheck(false)
  }

  const handleAuthorityNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      roles &&
      roles.selectedRowData.row &&
      roles.selectedRowData.row.authorityAlias
    ) {
      roles.selectedRowData.row.authorityAlias = ''
    }
    setAuthorityAliasName(e.target.value)
    setNameCheck(false)
  }

  const handleSave = () => {
    const rolePolicies= roles?.selectedRowData?.row?.policy?(roles?.selectedRowData?.row?.policy):[];

    const attachPolicyIdArray = attachedPoliciesToRole
      .filter((x: any) => !rolePolicies.includes(x.name))
      .map((item: any) => item.id)

    let detachPolicyIdArray = rolePolicies
      ? rolePolicies.filter(
          (item: string) => !existingAttached.map((x) => x.name).includes(item)
        )
      : []

    detachPolicyIdArray = roles?.roles?.roles?.policies
      ?.filter((x: any) => detachPolicyIdArray.includes(x.name))
      .map((x: any) => x.id)

    const payloadObj = {
      authority: authorityKey,
      authorityAlias: authorityAliasName,
      isActive: true,
      currentUser:
        roles && roles.roles && roles.roles.roles
          ? roles.roles.roles.username
          : ''
    }

    if (!authorityKey) {
      setKeyCheck(true)
    }

    if (!authorityAliasName) {
      setNameCheck(true)
    }

    if (attachPolicyIdArray.length === 0) {
      setAttachPolicyCheck(true)
    }

    if (
      roles &&
      roles.roles &&
      authorityKey &&
      authorityAliasName &&
      !attachPolicyCheck
    ) {
      const rolesData = roles.roles.roles

      const policyPayload: any = []

      if (attachPolicyIdArray && attachPolicyIdArray.length !== 0) {
        const attachObj: any = {
          action: 'attach',
          policy_id: attachPolicyIdArray,
          scope: 'global'
        }

        policyPayload.push(attachObj)
      }

      if (detachPolicyIdArray && detachPolicyIdArray.length !== 0) {
        const detachObj: any = {
          action: 'detach',
          policy_id: detachPolicyIdArray,
          scope: 'global'
        }

        policyPayload.push(detachObj)
      }

      if (roles && roles.selectedRowData.row && authorityKey) {
        const policyId = roles.selectedRowData.row.id
        payloadObj['id'] = policyId
        rolesData.updateRole(payloadObj, policyId, policyPayload, true)
      } else {
        rolesData.createRole(payloadObj, policyPayload)
      }

      setTimeout(function () {
        handleCancel()

        roles.roles.setRoleTabClicked(true)
      }, 4000)
    }

    localStorage.setItem('UrmServiceProvider', '1')
  }

  const handleCancel = () => {
    const rolesData = roles.roles.roles
    rolesData.getRolesData()
    roles.roles.setRoleTabClicked(true)
    roles.cancelRole()
  }

  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue)
  }

  return (
    <Fragment>
      <div>
        <div className='pb-20'>
          <Link
            component='button'
            className='fs-inherit'
            onClick={handleCancel}
          >
            {' '}
            Roles{' '}
          </Link>
          <span> / Create or Update</span>
        </div>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={3} sx={fwbSX}>
            Name: *
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                required
                id='authorityAliasName'
                sx={inputSX}
                value={
                  roles &&
                  roles.selectedRowData.row &&
                  roles.selectedRowData.row.authorityAlias
                    ? roles.selectedRowData.row.authorityAlias
                    : authorityAliasName
                }
                onChange={handleAuthorityNameChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} className={styles.userLabel} />
          <Grid item xs={9}>
            {nameCheck && (
              <div className='red-color'>* Name field is empty</div>
            )}
          </Grid>
          <Grid item xs={3} sx={fwbSX}>
            Key : *
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                id='authorityAliasName'
                sx={inputSX}
                value={
                  roles &&
                  roles.selectedRowData.row &&
                  roles.selectedRowData.row.authority
                    ? roles.selectedRowData.row.authority
                    : authorityKey
                }
                onChange={handleAuthorityKeyChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} className={styles.userLabel} />
          <Grid item xs={9}>
            {keyCheck && (
              <div className='red-color'>* Key field is empty</div>
            )}
          </Grid>
          <Grid item xs={8}>
            <Tabs variant='scrollable' value={value} onChange={handleChange}>
              <Tab label='Policy' {...a11yProps(0)} sx={{ height: 100 }} />
              <Tab label='Permissions' {...a11yProps(1)} sx={{ height: 100 }} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <AttachPolicy
                policyList={policyData || policyList}
                existingAttached={existingAttached}
                setExistingAttach={setExistingAttach}
                attachedPoliciesToRole={attachedPoliciesToRole}
                setAttachedPoliciesToRole={setAttachedPoliciesToRole}
              />
              {!(existingAttached?.length > 0) && (
                <div className='red-color'>
                  * At least one policy to be attached
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PermissionsTable
                existingAttached={existingAttached}
                attachedPoliciesToRole={attachedPoliciesToRole}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={12}>
            Note: Once policies are attached, User must Save the role.
          </Grid>

          <div className='pt-20'>
            {disableSave && (
              <Button
                disabled={disableSave}
                style={{
                  width: 30,
                  opacity: 0.5,
                  backgroundColor: '#1E64D7',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            {!disableSave && (
              <Button
                style={{
                  width: 30,
                  backgroundColor: '#1E64D7',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            <Button
              style={{
                marginLeft: 10,
                width: 30,
                backgroundColor: '#1E64D7',
                color: 'white',
                textTransform: 'capitalize'
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </div>
    </Fragment>
  )
}
