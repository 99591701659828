import * as React from 'react'
// import styles from './UserList.module.css'
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Checkbox
} from '@mui/material'
import { useState, useEffect } from 'react'

export const UsersRolesTable = (props: any) => {  
  const [selectedRoles, setSelectedRoles] = useState<any>([])
  const [selectedRolesPresent, setSelectedRolesPresent] = useState(false)
  const [roles,setRoles] = useState<any>([])

  useEffect(() => {
    if (props) {
      props.selectedRolesList && setSelectedRoles(props.selectedRolesList);
      props.roleTableList && setRoles(props.roleTableList);
    }
  }, [props])

  useEffect(() => {
    if (selectedRoles && selectedRoles.length !== 0) {
      setSelectedRolesPresent(true)
    }
  }, [selectedRoles])

  const handleCheckChange = (event: any, item: any) => {
    const arr: any = [...props.selectedRolesList]
    if (event.target.checked) {
      if (!arr.includes(item)) {
        arr.push(item)
      }
      props.setSelectedRolesList(arr)
    } else {
      if (arr.includes(item)) {
        const tempArr = arr.filter((el: any) => el !== item)
        props.setSelectedRolesList(tempArr)
      }
    }
  }

  return (
    <div>
      <TableContainer sx={{ height: 200, width: 500 }}>
        <Table aria-label='Roles select table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> Roles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles
              ? roles.map(
                  (item: any) =>
                    item &&
                    item.isActive && (
                      <TableRow key={item.id}>
                        <TableCell style={{ width: 100 }}>
                          <Checkbox
                            checked={
                              selectedRoles &&
                              selectedRoles.includes(item.authority)
                            }
                            onChange={(event) =>
                              handleCheckChange(event, item.authority)
                            }
                          />
                        </TableCell>
                        <TableCell>{item.authority}</TableCell>
                      </TableRow>
                    )
                )
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
      {(props.selectedRolesList.length===0 && props.required==true) && (
        <div style={{ color: 'red' }}>
          * At least one role should be selected{' '}
        </div>
      )}
    </div>
  )
}
