import * as React from 'react'
import styles from './RoleList.module.css'
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Checkbox
} from '@mui/material'
import { Fragment, useState, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

import '../styles.module.css';

const PolicyTable = (props: any) => {
  const policies = props.policyArray
  const [selectedPolicyArray, setSelectedPolicyArray] = useState([])

  const handleCancel = () => {
    props.setAttachPolicyClicked(false)
  }

  const handleSelect = () => {
    props.handleAttachedPoliciesList(selectedPolicyArray)
    props.setAttachPolicyClicked(false)
  }

  const handleCheckChange = (event: any, item: any) => {
    if (event && event.target.checked) {
      const arr: any = [...selectedPolicyArray]

      let elementPresent = false
      arr.forEach((el: any) => {
        if (el.id === item.id) {
          elementPresent = true
        }
      })
      if (!elementPresent) {
        arr.push(item)
      }
      setSelectedPolicyArray(arr)
     } 
    
  }

  const handleDeleteChange = (item: any) => {
    setTimeout(() => {
      const temp = props.policyArray.filter((row: any) => row.id !== item.id)      
      props.setTotalPolicyList(temp)
    })
  }

  const h200 = { height: 200 };

  return (
    <div>
      <TableContainer sx={h200}>
        <Table aria-label='policy select table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell> Policy</TableCell>
              <TableCell> Description </TableCell>
              <TableCell> {props.showList ? 'Select' : 'Delete'} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policies
              ? policies.map((item: any) => (
                  <TableRow key={item.name}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {props.showList && (
                        <Checkbox
                          onChange={(event: any) =>
                            handleCheckChange(event, item)
                          }
                        />
                      )}
                      {!props.showList && (
                        <DeleteIcon className='red-color' onClick={() => handleDeleteChange(item)} />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : ''}
          </TableBody>
        </Table>
      </TableContainer>

      {props.showList && (
        <div>
          <Button
            className='saveBtn'
            onClick={handleSelect}
          >
            Attach
          </Button>
          <Button
            className='cancelBtn'
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}

export const AttachPolicy = (policyList: any) => {
  const [attachPolicyCliked, setAttachPolicyClicked] = useState(false)
  const [policyArray, setPolicyListArray] = useState([])
  const [attachedPolicyList, setAttachedPolicyList] = useState<Array<any>>([])
  const [existitngAttached, setExistingAttached] = useState<Array<any>>([])
  const [totalPolicyList, setTotalPolicyList] = useState<Array<any>>([])
  
  useEffect(() => {
    if (policyList) {
      const remove=policyList.existingAttached.map((x:any)=>x.id)
      const temp=policyList.policyList.filter((x:any)=>!remove.includes(x.id))
      setPolicyListArray(temp)
    }
    if (policyList && policyList.existingAttached) {
      setExistingAttached(policyList.existingAttached)
      
    }
  }, [policyList])

  const handleAttachPolicyClick = () => {
    setAttachPolicyClicked(true)
  }

  const handleAttachedPoliciesList = (selectedPolicyArray: any) => {    
    setAttachedPolicyList(selectedPolicyArray)
  }

  useEffect(() => {
    
    if (existitngAttached && attachedPolicyList) {
      const totalAttached = [...existitngAttached, ...attachedPolicyList]      
      setTotalPolicyList(totalAttached)
    }
  }, [attachedPolicyList])

  useEffect(() => {    
    if(totalPolicyList.length>0){
      policyList.setAttachedPoliciesToRole(totalPolicyList);
      policyList.setExistingAttach(totalPolicyList)
    }
    
  }, [totalPolicyList])

  const floatSX = { fload: 'right' };

  return (
    <Fragment>
      <Grid container direction='row' spacing={2}>
        <Grid item xs={9} className={styles.userLabel}>
          Attached Policies
        </Grid>
        <Grid item xs={3} sx={floatSX}>
          <Button onClick={handleAttachPolicyClick} className='tt-caps'>+ Attach Policy</Button>
        </Grid>
        <Grid item xs={12}>
          {attachPolicyCliked && policyArray && (
            <PolicyTable
              policyArray={policyArray}
              existitngAttached={existitngAttached}
              setAttachPolicyClicked={setAttachPolicyClicked}
              handleAttachedPoliciesList={handleAttachedPoliciesList}
              showList={true}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {!attachPolicyCliked && attachedPolicyList && (
            <PolicyTable
              policyArray={existitngAttached}
              setAttachPolicyClicked={setAttachPolicyClicked}
              setTotalPolicyList={setTotalPolicyList}
              showList={false}              
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}
