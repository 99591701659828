import * as React from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer
} from '@mui/material'

import '../../styles.module.css';

export const PermissionsTable = (props: any) => {

  const policies = [...props.attachedPoliciesToRole, ...props.existingAttached]

  const permissions =
    props && policies
      ? policies
          .map((item: any) => item.policyJson)
          .map((item: any) => item.permissions)
      : []

  const temp = Array.prototype.concat.apply([], permissions)

  const permsObj: any = {}

  temp.forEach((item: any) => {
    if (!Object.keys(permsObj).includes(item.resource_type)) {
      permsObj[item.resource_type] = item.action
    } else {
      permsObj[item.resource_type] = permsObj[item.resource_type].concat(
        item.action
      )
    }
  })

  const permArray = Object.entries(permsObj);

  const dynamicSX = { height: 200, width: 500 };

  return (
    <div>
      <TableContainer sx={dynamicSX}>
        <Table aria-label='policy select table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell> Resource Type</TableCell>
              <TableCell> Permissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permArray
              ? permArray.map((item: any) => (
                  <TableRow key={item[0]}>
                    <TableCell className='w-200'>{item[0]}</TableCell>
                    <TableCell>
                      {Array.from(new Set(item[1])).toString()}
                    </TableCell>
                  </TableRow>
                ))
              : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
