import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import Divider from '@mui/material/Divider'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, { Fragment, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { CreatePolicy } from './CreatePolicy'
// import PolicyIcon from '@mui/icons-material/Policy';
import DeleteIcon from '@mui/icons-material/Delete'
import { ConfirmPopup } from '../Utilities/ConfirmPopup';

import styles from './PolicyList.module.css'
import '../styles.module.css';

const doNotDelete = [
  'READ_ONLY_POLICY',
  'ROLE_SERVICE_POLICY',
  'ROLE_ADMIN_POLICY',
  'READ_WRITE_POLICY',
  'Dib'
]

const DeleteComp = (props: any) => {
  return (
    <div>
      {props &&
        props.data &&
        props.data.row &&
        !doNotDelete.includes(props.data.row.name) && <DeleteIcon style={{color: 'red'}}/>}
    </div>
  )
}

const _col=[
  { field: 'name', headerName: 'Policy Name', width: 400 ,search:true , headerClassName: 'headerClass'},
  { field: 'description', headerName: 'Description', width: 500 ,search:true , headerClassName: 'headerClass'},
  { field: 'dateCreated', headerName: 'Date Created', width: 150 ,search:true , headerClassName: 'headerClass'},
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 250,
    search:true,
    headerClassName: 'headerClass'
  },
  {
    field: 'isDeleted',
    headerName: 'Delete',
    width: 100,
    headerClassName: 'headerClass',
    renderCell: (data: any) => <DeleteComp data={data} />
  }
]
const columns: GridColDef[] =_col.map(x=>x);


export const PolicyList = (policies: any) => {
  const [policyListToShow, setPolicyListToShow] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [policyShow, setPolicyShow] = useState(true)
  const [selectedRowData, setSelectedRowData] = useState({})
  const [showPopup, setShowPopup] = useState(false)
  const [rowParams, setRowParams] = useState<any>({})

  const createPolicyBtn = {
    width: 130,
    backgroundColor: '#1E64D7',
    color: '#ffffff',
    float: 'right',
    fontSize: '15px',
    fontWeight: '500',
    borderRadius: '4px',
    display: 'inline-block',
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    padding: '7px',
    marginRight: '6px'
  };

  useEffect(() => {
    const policyTab = policies.policyTabClicked
    setPolicyShow(policyTab)
  }, [policies])

  useEffect(() => {
    const policyList = policies.policies.policies || []

    const filtered = policyList
      ? policyList.filter((item: any) => {
       const cols=_col.filter(x=>x.search) 
       let found=false;
       cols.forEach((col:any)=>{         
        if(!found){
         try {  found=JSON.stringify(item[col.field]).toLowerCase().includes(searchVal.toLowerCase())
         }
         catch(e){
          
         }
          
        }
       })
       return found;          
        })
      : []

    setPolicyListToShow(filtered)
  }, [searchVal, policies,policyShow])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  const handleCreatePolicyClick = () => {
    setPolicyShow(false)
    policies.viewModeChanged('create');
    policies.setPolicyTabClicked(false)
    setSelectedRowData({})
    setSearchVal('')
  }

  const handleCancelCreatePolicy = () => {
    policies.viewModeChanged('list');
    setPolicyShow(true)
  }

  const handleOnCellClick = (params: any) => {
    if (params && params.field === 'name') {
      setPolicyShow(false)
      policies.viewModeChanged('edit');
      policies.setPolicyTabClicked(false)
      setSelectedRowData(params)
      setSearchVal('')
    }

    if (params && params.field === 'isDeleted') {
      if (!doNotDelete.includes(params.row.name)) {
        setShowPopup(true)
        setRowParams(params)
      }
    }
  }

  const deletePolicy = () => {
    const deletePolicy = policies.policies.deletePolicy
    if (rowParams && rowParams?.row) {
      deletePolicy(rowParams?.row?.id)
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const dynamicGridSX = {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 45
  };

  const dynamicInputSX = { ml: 1, flex: 1 }
  const dataGridDiv = { height: 600, paddingTop: 20 }

  return (
    <Fragment>
      {policyShow && (
        <div className={`${styles.main} + ${styles.mainDiv}`}>
          Search
          <span className={styles.filters}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <Grid item xs={4} className='searchBox'>
                <Paper
                  component='form'
                  sx={dynamicGridSX}
                >
                  <InputBase
                    sx={dynamicInputSX}
                    placeholder='Search Policies'
                    inputProps={{ 'aria-label': 'search global' }}
                    onChange={onChange}
                    onKeyDown={handleKeyPress}
                  />
                  <SearchIcon />
                </Paper>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                {/* <span> <PolicyIcon sx={{ float: 'right'}} /> </span> */}
                <span>
                  <Button
                    className = 'policyBttn'
                    onClick={handleCreatePolicyClick}
                  >
                    Create Policy
                  </Button>
                </span>
              </Grid>
            </Grid>
            <div />

            <div />
          </span>
          {/* <Divider sx={{ paddingTop: 2 }} /> */}
          <div style={dataGridDiv}>
            <DataGrid
              rows={policyListToShow}
              columns={columns}
              pageSize={8}
              rowsPerPageOptions={[10]}
              // onRowClick={handleRowClick}
              getRowClassName={(params) => `rowClass`}
              onCellClick={handleOnCellClick}
              className='bo-none'
            />
          </div>
        </div>
      )}

      {!policyShow && (
        <div>
          <CreatePolicy
            policies={policies}
            cancelCreatePolicy={handleCancelCreatePolicy}
            selectedRowData={selectedRowData}
          />
        </div>
      )}

      {showPopup && (
        <ConfirmPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          deletePolicy={deletePolicy}
        />
      )}
    </Fragment>
  )
}
