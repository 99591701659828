import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import styles from './UserList.module.css'
import {
  Button,
  FormControl,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography,
  Link
} from '@mui/material'
import { Fragment, useState, useRef } from 'react'
import { UsersRolesTable } from './UserTabs/UsersRolesTable'
// import { UsersSegmentTable } from './UserTabs/UserSegmentTable'
// import { UsersCustomerTable } from './UserTabs/UserCustomerTable'
// import { UsersProductTable } from './UserTabs/UserProductTable'
// import { ItemSelectTable } from '../CommanComponents/ItemSelectTable'
import { TabPanel, a11yProps } from '../CommanComponents/TablePanel'

function usePrevious(value: any) {
  const ref = useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const UpdateUser = (users: any) => {
  const [username, setUsername] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [value, setValue] = useState(0)

  const [selectedRolesList, setSelectedRolesList] = useState<Array<any>>([])
  const [selectedCustomerList, setSelectedCustomerList] = useState<Array<any>>(
    []
  )
  const [selectedSegmentList, setSelectedSegmentList] = useState<Array<any>>([])
  const [selectedProductList, setSelectedProductList] = useState<Array<any>>([])
  const [selectedGroupList, setSelectedGroupList] = useState<Array<any>>([])
  const [disableSave, setDisableSave] = useState(true)
  React.useEffect(() => {
    if (users && users.users && users.selectedRowData) {
      users?.users?.users?.getAccessDataOfUser(users.selectedRowData.row.id)
      users?.users?.users?.getGroupsDataOfUser(users.selectedRowData.row.id)
    }
  }, [])

  React.useEffect(() => {
    setDisableSave(
      !(selectedRolesList.length > 0 || selectedGroupList.length > 0)
    )
  }, [
    selectedRolesList,
    selectedCustomerList,
    selectedSegmentList,
    selectedProductList,
    selectedGroupList
  ])

  React.useEffect(() => {
    if (users && users.users.users && users.users.users.currentUserGroupsData) {
      setSelectedGroupList(users.users.users.currentUserGroupsData)
    }
    if (
      users &&
      users.users.users &&
      users.users.users.currentUserCustomerData
    ) {
      setSelectedCustomerList(users.users.users.currentUserCustomerData)
    }
    if (
      users &&
      users.users.users &&
      users.users.users.currentUserProductData
    ) {
      setSelectedProductList(users.users.users.currentUserProductData)
    }
    if (
      users &&
      users.users.users &&
      users.users.users.currentUserSegmentData
    ) {
      setSelectedSegmentList(users.users.users.currentUserSegmentData)
    }
    if (
      users &&
      users.users &&
      users.selectedRowData &&
      users.selectedRowData.row
    ) {
      setSelectedRolesList(users.selectedRowData.row.roles)
    }
  }, [users])

  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue)
  }

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }

  const handleFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(e.target.value)
  }

  const handleLastname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(e.target.value)
  }

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSave = () => {
    if (
      selectedRolesList &&
      users &&
      users.users.users &&
      users.users.users.roles
    ) {
      const authorities: Array<any> = users.users.users.roles
        .map((item: any) => {
          return {
            id: item.id,
            authority: item.authority,
            authorityAlias: item.authorityAlias,
            isActive: item.isActive
          }
        })
        .filter((item: any) => selectedRolesList.includes(item.authority))

      const temp = { ...users.selectedRowData.row }
      temp.authorities = authorities
      temp.currentUser =
        users && users.users && users.users.users
          ? users.users.users.username
          : ''
      temp.enabled = true
      delete temp.roles
      delete temp.isActive
      users.users.users.updateUser(
        users.selectedRowData.id,
        temp,
        selectedGroupList
      )
    }

    if (selectedCustomerList && selectedSegmentList && selectedProductList) {
      const dataArray: any = []

      selectedCustomerList.forEach((el: any) => {
        const obj = {
          objectType: 'customer',
          objectId: el
        }
        dataArray.push(obj)
      })

      selectedSegmentList.forEach((el: any) => {
        const obj = {
          objectType: 'segment',
          objectId: el
        }
        dataArray.push(obj)
      })

      selectedProductList.forEach((el: any) => {
        const obj = {
          objectType: 'product',
          objectId: el
        }
        dataArray.push(obj)
      })

      const accessDataObj = {
        entityId: users.selectedRowData.row.id,
        data: dataArray,
        entityType: 'user'
      }
      users.users.users.updateUserAccessData(accessDataObj)
    }
    localStorage.setItem('UrmServiceProvider', '1')
    setTimeout(function () {
      handleCancel()
      users.users.setUserTabClicked(true)
    }, 3000)
  }

  const handleCancel = () => {
    users.handleCancelUserClick()
  }

  return (
    <Fragment>
      <div>
        <div style={{ paddingBottom: 20 }}>
          <Link
            component='button'
            style={{ fontSize: 'inherit' }}
            onClick={handleCancel}
          >
            {' '}
            User{' '}
          </Link>
          <span> / Update</span>
        </div>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
            {' '}
            User Name: *{' '}
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                disabled
                id='username'
                sx={{ width: 500 }}
                value={
                  users &&
                  users.selectedRowData.row &&
                  users.selectedRowData.row.username
                    ? users.selectedRowData.row.username
                    : username
                }
                onChange={handleUsername}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
            {' '}
            First Name: *{' '}
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                disabled
                id='firstname'
                sx={{ width: 500 }}
                value={
                  users &&
                  users.selectedRowData.row &&
                  users.selectedRowData.row.firstName
                    ? users.selectedRowData.row.firstName
                    : firstname
                }
                onChange={handleFirstname}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
            {' '}
            Last Name: *{' '}
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                disabled
                id='lastname'
                sx={{ width: 500 }}
                value={
                  users &&
                  users.selectedRowData.row &&
                  users.selectedRowData.row.lastName
                    ? users.selectedRowData.row.lastName
                    : lastname
                }
                onChange={handleLastname}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
            {' '}
            Email: *{' '}
          </Grid>
          <Grid item xs={9}>
            <FormControl className={styles.userText}>
              <OutlinedInput
                disabled
                id='email'
                sx={{ width: 500 }}
                value={
                  users &&
                  users.selectedRowData.row &&
                  users.selectedRowData.row.email
                    ? users.selectedRowData.row.email
                    : email
                }
                onChange={handleEmail}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <Tabs variant='scrollable' value={value} onChange={handleChange}>
              <Tab
                label='Roles'
                {...a11yProps(0)}
                sx={{ height: 50, width: 150 }}
              />
              {/* <Tab
                label='Customers'
                {...a11yProps(1)}
                sx={{ height: 50, width: 150 }}
              />
              <Tab
                label='Segments'
                {...a11yProps(2)}
                sx={{ height: 50, width: 150 }}
              />
              <Tab
                label='Products'
                {...a11yProps(3)}
                sx={{ height: 50, width: 150 }}
              />
              <Tab
                label='Groups'
                {...a11yProps(4)}
                sx={{ height: 50, width: 150 }}
              /> */}
            </Tabs>
            <TabPanel value={value} index={0}>
              <UsersRolesTable
                required={false}
                roleTableList={
                  users && users.users && users.users.users.roles
                    ? users.users.users.roles
                    : []
                }
                users={users}
                selectedRolesList={selectedRolesList}
                setSelectedRolesList={setSelectedRolesList}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <UsersCustomerTable
                customers={
                  users && users.users && users.users.users.customers
                    ? users.users.users.customers
                    : []
                }
                selectedCustomerList={selectedCustomerList}
                setSelectedCustomerList={setSelectedCustomerList}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UsersSegmentTable
                segments={
                  users && users.users && users.users.users.segments
                    ? users.users.users.segments
                    : []
                }
                selectedSegmentList={selectedSegmentList}
                setSelectedSegmentList={setSelectedSegmentList}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <UsersProductTable
                products={
                  users && users.users && users.users.users.products
                    ? users.users.users.products
                    : []
                }
                selectedProductList={selectedProductList}
                setSelectedProductList={setSelectedProductList}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ItemSelectTable
                items={users?.users?.users?.groups}
                name='Groups'
                selectedItems={selectedGroupList}
                select={setSelectedGroupList}
              />
            </TabPanel> */}
          </Grid>
          <Grid item xs={6}>
            {selectedRolesList.length === 0 &&
              selectedGroupList.length === 0 && (
                <div style={{ color: 'red' }}>
                  * At least one role/group should be selected
                </div>
              )}
          </Grid>
          <Grid item xs={12}>
            Note: Select Roles, Customers, Segements, Products and Groups{' '}
          </Grid>
          <div style={{ paddingTop: 20 }}>
            {disableSave && (
              <Button
                disabled={disableSave}
                style={{
                  width: 30,
                  opacity: 0.5,
                  backgroundColor: '#1E64D7',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            {!disableSave && (
              <Button
                style={{
                  width: 30,
                  backgroundColor: '#1E64D7',
                  color: 'white',
                  textTransform: 'capitalize'
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
            <Button
              style={{
                marginLeft: 10,
                width: 30,
                backgroundColor: '#1E64D7',
                color: 'white',
                textTransform: 'capitalize'
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </div>
    </Fragment>
  )
}
