import React, { Fragment, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import styles from './UserList.module.css'
import Divider from '@mui/material/Divider'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { UpdateUser } from './UpdateUser'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

const CheckboxComponent = (props: any) => {
  const rowValues = props.params
  return <Checkbox checked={rowValues.row.isActive} />
}

const _col = [
  { field: 'username', headerName: 'User Name', width: 300, search: true, headerClassName: 'headerClass' },
  { field: 'firstName', headerName: 'First Name', width: 250, search: true, headerClassName: 'headerClass' },
  { field: 'lastName', headerName: 'Last Name', width: 250, search: true, headerClassName: 'headerClass' },
  {
    field: 'roles',
    headerName: 'Roles',
    width: 500,
    search: true,
    headerClassName: 'headerClass'
  },
  {
    field: 'isActive',
    headerName: 'Enabled',
    width: 100,
    headerClassName: 'headerClass',
    renderCell: (params: any) => <CheckboxComponent params={params} />
  }
]
const columns: GridColDef[] = _col.map((x) => x)

// const linkTo = `/createUser`
// let userListShow = true;

export const UsersList = (users: any) => {
  const [userListToShow, setUserListToShow] = useState([])
  const [searchVal, setSearchVal] = useState('')
  const [userListShow, setUserListShow] = useState(true)
  const [selectedRowData, setSelectedRowData] = useState({})
  useEffect(() => {
    const userList = users.users.users || []

    const filtered = userList
      ? userList.filter((item: any) => {
          const cols = _col.filter((x) => x.search)
          let found = false
          cols.forEach((col: any) => {
            if (!found) {
              try {
                found = JSON.stringify(item[col.field])
                  .toLowerCase()
                  .includes(searchVal.toLowerCase())
              } catch (e) {}
            }
          })
          return found
        })
      : []

    setUserListToShow(filtered)
  }, [searchVal, users, userListShow])

  useEffect(() => {
    const userTab = users.userTabClicked
    setUserListShow(userTab)
  }, [users])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value)
  }

  const handleUserClick = () => {
    setUserListShow(false)
  }

  const handleCancelUserClick = () => {
    users?.viewModeChanged('list')
    setUserListShow(true)
  }

  const handleOnCellClick = (params: any) => {
    if (params && params.field === 'username') {
      users?.viewModeChanged('edit')
      setUserListShow(false)
      setSelectedRowData(params)
      users.setUserTabClicked(false)
      setSearchVal('')
    }

    if (params && params.field === 'isActive' && users) {
      const userUpdate = users.users.updateUser
      const temp = { ...params.row }
      temp.isActive = !params.row.isActive

      userUpdate(params.id, temp)
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <Fragment>
      {userListShow && (
        <div className={styles.main} style={{backgroundColor: '#ffffff', padding: '16px'}}>
          Search
          <span className={styles.filters}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <Grid item xs={4} className='searchBox'>
                <Paper
                  component='form'
                  sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: 45
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder='Search UserName'
                    inputProps={{ 'aria-label': 'search global' }}
                    onChange={onChange}
                    onKeyDown={handleKeyPress}
                    className='inputClass'
                  />
                  <SearchIcon />
                </Paper>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <span>
                  <Button
                    sx={{
                      fontWeight: '600',
                      float: 'right',
                      textTransform: 'capitalize'
                    }}
                    onClick={handleUserClick}
                  />
                </span>
              </Grid>
            </Grid>

            <div />
            <div />
            <div className={styles.create} />
          </span>
          {/* <Divider sx={{ paddingTop: 2 }} /> */}
          <div style={{ height: 600, paddingTop: 20 }}>
            <DataGrid
              rows={userListToShow}
              columns={columns}
              pageSize={8}
              rowsPerPageOptions={[10]}
              getRowClassName={(params) => `rowClass`}
              onCellClick={handleOnCellClick}
              style={{ border: 'none' }}
            />
          </div>
        </div>
      )}
      {!userListShow && (
        <div>
          <UpdateUser
            selectedRowData={selectedRowData}
            users={users}
            handleCancelUserClick={handleCancelUserClick}
          />
        </div>
      )}
    </Fragment>
  )
}
