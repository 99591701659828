import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import PersonIcon from '@mui/icons-material/Person'
import PolicyIcon from '@mui/icons-material/Policy'
// import GroupIcon from '@mui/icons-material/Group'

import { UsersList } from './Users/UsersList'
import { RoleList } from './Roles/RoleList'
import { PolicyList } from './Policies/PolicyList'
// import { GroupList } from './Groups/GroupList'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './styles.module.css'

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export function UrmComponent(users: any) {
  const [value, setValue] = React.useState(0)

  const [currentTab, setCurrentTab] = useState(0)
  const [policyTabClicked, setPolicyTabClicked] = React.useState(false)
  const [roleTabClicked, setRoleTabClicked] = React.useState(false)
  const [userTabClicked, setUserTabClicked] = React.useState(true)
  // const [groupTabClicked, setGroupTabClicked] = React.useState(false)
  // const [viewModel,setViewMode]=React.useState("list")
  const handleChange = (_event: any, newValue: any) => {
    setValue(newValue)
    if (users?.tableChanged) users.tableChanged(newValue)
  }
  const setMode = (model: string) => {
    if (users?.viewModeChanged) users.viewModeChanged(model)
  }
  const onPolicyTabClick = () => {
    setCurrentTab(2)
    setPolicyTabClicked(true)
    setMode('list')
  }

  // const onGroupTabClick = () => {
  //   setCurrentTab(3)
  //   setGroupTabClicked(true)
  //   setMode('list')
  // }

  const onRoleTabClick = () => {
    setCurrentTab(1)
    setRoleTabClicked(true)
    setMode('list')
  }

  const onUserTabClick = () => {
    setCurrentTab(0)
    setUserTabClicked(true)
    setMode('list')
  }

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex'
        }}
      >
        <div style={{ flex: 'none' }} />
        <div style={{ overflow: 'auto', flex: 1 }}>
          {/* <TabPanel value={value} index={3}>
          <GroupList
            groups={users}
            viewModeChanged={setMode}
          />
        </TabPanel> */}
        </div>
      </Box>
      <Container>
        <Row>
          <Col sm={12}>
            <Tabs
              orientation='horizontal'
              variant='scrollable'
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#36bb9b'
                }
              }}
              aria-label='horizontal tabs example'
              className='navMainBar'
            >
              <Tab
                icon={<GroupAddIcon />}
                iconPosition='start'
                label='Users'
                {...a11yProps(0)}
                onClick={onUserTabClick}
                className={currentTab === 0 ? 'ActiveTab' : 'tabs'}
              />
              <Tab
                icon={<PersonIcon />}
                iconPosition='start'
                label='Roles'
                {...a11yProps(1)}
                onClick={onRoleTabClick}
                className={currentTab === 1 ? 'ActiveTab' : 'tabs'}
              />
              <Tab
                icon={<PolicyIcon />}
                iconPosition='start'
                label='Policies'
                onClick={onPolicyTabClick}
                {...a11yProps(2)}
                className={currentTab === 2 ? 'ActiveTab' : 'tabs'}
              />
              {/* <Tab
            icon={<GroupIcon />}
            label='Groups'
            onClick={onGroupTabClick}
            {...a11yProps(2)}
            sx={[
              { height: 100 },
              {
                '&:hover': {
                  color: '#86bc25',
                  backgroundColor: 'white'
                }
              }
            ]}
          /> */}
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TabPanel value={value} index={0}>
              <UsersList
                users={users}
                viewModeChanged={setMode}
                userTabClicked={userTabClicked}
                setUserTabClicked={setUserTabClicked}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RoleList
                roles={users}
                viewModeChanged={setMode}
                roleTabClicked={roleTabClicked}
                setRoleTabClicked={setRoleTabClicked}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PolicyList
                policies={users}
                viewModeChanged={setMode}
                policyTabClicked={policyTabClicked}
                setPolicyTabClicked={setPolicyTabClicked}
              />
            </TabPanel>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
